<template>
    <comp-table title="供应商营业报表汇总" :columns="columns" :isPage="false" :table-api="'/gateway/apps/o2o/api/terminal/goods/getSupplierSalesReport'" :watch="watch" :dataBefore="onDataBefore">
        <template v-slot:search="data">
            <RadioGroup v-model="data.search.type" type="button">
                <Radio label="全部"></Radio>
                <Radio label="本年"></Radio>
                <Radio label="本月"></Radio>
                <Radio label="本日"></Radio>
                <Radio label="自定义"></Radio>
            </RadioGroup>
            <FormItem v-show="isCustomize" label="时间段" :label-width="100" style="margin-bottom: 0">
                <DatePicker v-model="data.search.date" type="daterange" style="width: 250px"></DatePicker>
            </FormItem>
        </template>
    </comp-table>
</template>

<script>
import CompTable from "../../../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            isCustomize: false,

            columns: [
                {
                    title: "供应商",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "数量",
                    key: "num",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "销售额",
                    key: "sales",
                    minWidth: 200,
                    align: "center",
                },
            ],

            watch: {
                type: v => {
                    this.isCustomize = v === "自定义"
                },
            },
        }
    },

    mounted() {},

    methods: {
        // 监听数据前置事件
        onDataBefore(data) {
            const res = new Object(null)

            if (data.type == "自定义") {
                if (!data.date) {
                    this.$Message.error({
                        content: "请选择时间",
                        background: true,
                    })

                    return false
                }

                res.startDate = this.$core.formatDate(data.date[0], "yyyy-MM-dd")
                res.endDate = this.$core.formatDate(data.date[1], "yyyy-MM-dd")
            } else if (data.type != "全部") {
                res.date = { 本月: "MONTH", 本年: "YEAR", 本日: "DAY" }[data.type]
            }

            return res
        },
    },
}
</script>
<style lang="less"></style>
